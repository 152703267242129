import { createStore, applyMiddleware } from "redux"
import reducer from './reducer'
import { persistReducer } from "redux-persist"
import storage from "redux-persist/es/storage"
import logger from "redux-logger"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"

const persistConfig = {
    key: "root",
    storage,
  }

const persistedReducer =  persistReducer(persistConfig, reducer)

export const store = createStore( persistedReducer, composeWithDevTools(applyMiddleware(thunk, logger)))