import React, { Component } from "react";
import "./theme.scss";
import "./assets/css/themify-icons.css";
import { BrowserRouter as Router, Routes, Navigate, Route } from "react-router-dom";


import Index from "./landing/index"
import Login from "./member-page/login"
import Logout from "./member-page/login/logout";
import HomePage from "./member-page/home-page"
import { connect } from "react-redux"


class App extends Component {

  render(){
    console.log('ini props dari app', this.props)
    return(
      <>
        <Router basename={`/`}>
            <Routes>
              <Route path="/" element={<Index/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/home" element={<HomePage/>} />
              <Route path="/logout" element={<Logout/>} />
              <Route path="/home" element={
                    this.props.data.status_login === true ? 
                    (
                      <Navigate replace to="/home" />
                    )
                    :
                    (
                      <Login />
                    )

                  } />
              
            </Routes>
        </Router>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.auth.dataUser,
})

export default connect(mapStateToProps, null)(App)
