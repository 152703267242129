import { Component } from "react";
import './login-style.css'

import { connect } from "react-redux";
import { login } from '../../config/action/auth'

import logo from '../../assets/images/logo-black.png'


class Login extends Component{

    constructor(props){
        super(props)
        this.state = {
            email:'',
            password:'',
            showAlert:false,
            textLogin:'Login',
            disabledBtn:false,
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
 
    handleSubmit = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('email', this.state.email)
        formData.append('password', this.state.password)
        this.setState({
            textLogin:'Processing..',
            disabledBtn:true
        })
        this.props.loginUser(formData)
        .then(() => {
            this.setState({
                textLogin: 'Login',
                disabledBtn:false
            })
        })
        .catch(error => console.log(error))
        this.setState({
            textLogin:'Login',
            disabledBtn:false,
            showAlert:true
        })
    }

    render(){
        return (
            <>
            <section className="row justify-content-center">
                <div className="col-md-6">
                    <center><img src={logo} className='img-login' alt='images'/></center>
                    <form onSubmit={this.handleSubmit} className="form-login">
                        <h1>Login Membership</h1>
                        <div className="inset">
                            {
                                this.state.showAlert === true ?
                                <div className="alert alert-danger fade show" role="alert">
                                   <strong>Opps,</strong> mail atau password salah !
                                </div>
                                :
                                ''
                            }
                        <p>
                            <label className="label" for="email">EMAIL ADDRESS</label>
                            <input className="input-login" onChange={this.handleInput} value={this.state.email} type="text" name="email"/>
                        </p>
                        <p>
                            <label className="label" for="password">PASSWORD</label>
                            <input className="input-login" onChange={this.handleInput} value={this.state.password} type="password" name="password" />
                        </p>
                        </div>
                        <p className="p-container">
                            <a>Forgot password ?</a>
                            <button disabled={this.state.disabledBtn} type="submit" className="submit">{this.state.textLogin}</button>
                        </p>
                    </form>
                </div>
            </section>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    data: state.auth.dataUser,
})

const mapDispatchToProps = (dispatch) => ({
    loginUser: (data) => dispatch(login(data))
})

export default connect(mapStateToProps, mapDispatchToProps) (Login);