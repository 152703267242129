import React, { Component } from "react";
import { connect } from 'react-redux';
import { logout } from '../../config/action/auth'

class Logout extends Component {

  componentDidMount(){
    console.log(this.props)
  }


  render() {
    return(
        <div></div>
    );
  };
}

const mapStateToProps = (state) => ({

})


const mapDispatchToProps = (dispatch) => ({
 logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
