import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"
import './index.css'
import { Provider } from "react-redux"
import { store } from "./config"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/es/integration/react"

const persistor = persistStore(store, null)

const root = ReactDOM.createRoot(
    document.getElementById("root")
  );

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
)