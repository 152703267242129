import {
    GET_USER,
    LOGOUT
} from '../constant'

const initialState = {
    dataUser:{}
}

const Auth = ( state = initialState, {type, value = null} ) => {
    switch(type){
        case GET_USER:
            return {
                ...state,
                dataUser:value
            }
        case LOGOUT:{
            return {
                ...state,
                dataUser:{}
            }
        }
        default:
            return state
    }
}

export default Auth;