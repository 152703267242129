import services from '../service'

import {
    GET_USER,
    LOGOUT
} from '../constant'

export const login = (data) => (dispatch) => {
    return (
        services.POST('login',data)
        .then(response => {
            dispatch({ type: GET_USER, value: response })
        })
    )
}

export const logout = () => (dispatch) => {
    return (
        dispatch({ type: LOGOUT })
    )
}