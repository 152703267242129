import React, { Component, Suspense } from 'react';

import "../assets/css/style.css"
import "../assets/css/cards.scss"

const NavbarPage = React.lazy(() => import('./Navbar/Navbar_Page'));
const Section = React.lazy(() => import('./section'));
const About = React.lazy(() => import('./About'));
const Event = React.lazy(() => import('./Event'));
const Mapping = React.lazy(() => import('./Mapping'));
const Gallery = React.lazy(() => import('./Gallery'));
const Testimony = React.lazy(() => import('./Testimony'));
const Pricing = React.lazy(() => import('./Pricing'));
const Registrasi = React.lazy(() => import('./Registrasi'));
const Footer = React.lazy(() => import('./Footer/footer'));

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home" },
                { id: 2 , idnm : "features", navheading: "About" },
                { id: 3 , idnm : "clients", navheading: "Event" },
                { id: 4 , idnm : "gallery", navheading: "Gallery" },
                { id: 5 , idnm : "register", navheading: "Register" },
            ],
            pos : document.documentElement.scrollTop,
            imglight : true,
            navClass : ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        if(scrollup > this.state.pos)
        {
            this.setState({navClass : "darkheader", imglight : false});
        }
        else
        {
            this.setState({navClass : "", imglight : true});
        }
    };

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Suspense fallback = {this.Loader()} >

                    {/* Importing Navbar */}
                    <NavbarPage navItems={this.state.navItems} navclassName={this.state.navClass}/>

                    <Section/>

                    <About/>

                    <Event/>

                    <Mapping/>

                    <Gallery/>

                    <Testimony/>

                    <Pricing/>

                    <Registrasi/>

                    <Footer/>
                </Suspense>

            </React.Fragment>
        );
    }
}

export default Index;